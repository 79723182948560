import React from "react"
import { graphql } from "gatsby" 
import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok"
import Layout from "../Components/Layout"
 
 
const IndexPage = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
 
  const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
 
  return (
    <Layout>
        {components}
    </Layout>
  )
}
 
export default IndexPage
 
export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`